var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Vehicle Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Vehicle Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Vehicle Name"},model:{value:(_vm.vehiclename),callback:function ($$v) {_vm.vehiclename=$$v},expression:"vehiclename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Vehicle No.")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Vehical No"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"13","placeholder":"Vehical No."},on:{"input":function($event){return _vm.validateVehicalClick()}},model:{value:(_vm.vehicleno),callback:function ($$v) {_vm.vehicleno=$$v},expression:"vehicleno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.vehicleValidate == false)?_c('small',{staticClass:"text-danger"},[_vm._v("Enter Valid Vehical No")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Vehicle Colour")]),_c('b-form-input',{attrs:{"placeholder":"Enter Vehicle Colour"},model:{value:(_vm.vehiclecolour),callback:function ($$v) {_vm.vehiclecolour=$$v},expression:"vehiclecolour"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Fuel Type")]),_c('v-select',{attrs:{"options":['Petrol', 'Diesel', 'Gas', 'Electric', 'Hybrid'],"placeholder":"None"},model:{value:(_vm.fueltype),callback:function ($$v) {_vm.fueltype=$$v},expression:"fueltype"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Vehicle Type ")]),_c('label',{staticStyle:{"font-size":"10px !important"}},[_vm._v(" (Suggestion : Fourwheeler , Twowheeler ...)")]),_c('b-form-input',{attrs:{"placeholder":"Enter Vehicle Type"},model:{value:(_vm.vehicletype),callback:function ($$v) {_vm.vehicletype=$$v},expression:"vehicletype"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Insurance Issue Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Insurance Issue Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }},model:{value:(_vm.insurancestartdate),callback:function ($$v) {_vm.insurancestartdate=$$v},expression:"insurancestartdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Insurance End Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Insurance End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }},model:{value:(_vm.insuranceenddate),callback:function ($$v) {_vm.insuranceenddate=$$v},expression:"insuranceenddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("PUC Issue Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"PUC Issue Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }},model:{value:(_vm.pucstartdate),callback:function ($$v) {_vm.pucstartdate=$$v},expression:"pucstartdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("PUC End Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"PUC End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"config":{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }},model:{value:(_vm.pucenddate),callback:function ($$v) {_vm.pucenddate=$$v},expression:"pucenddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Photo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("PUC Photo")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('b-form-file',{attrs:{"placeholder":"Select Photo","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleFileChange($event, 'pucphoto')}},model:{value:(_vm.pucphoto),callback:function ($$v) {_vm.pucphoto=$$v},expression:"pucphoto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('attachment',{attrs:{"data":_vm.pucphoto}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Photo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("RC Book Front Side")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('b-form-file',{attrs:{"placeholder":"Select Photo","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleFileChange($event, 'rcbookphoto', 'front')}},model:{value:(_vm.rcbookphoto.front),callback:function ($$v) {_vm.$set(_vm.rcbookphoto, "front", $$v)},expression:"rcbookphoto.front"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('attachment',{attrs:{"data":_vm.rcbookphoto.front}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"RC Book Back Side"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("RC Book Back Side")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('b-form-file',{attrs:{"placeholder":"Select Photo","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleFileChange($event, 'rcbookphoto', 'back')}},model:{value:(_vm.rcbookphoto.back),callback:function ($$v) {_vm.$set(_vm.rcbookphoto, "back", $$v)},expression:"rcbookphoto.back"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('attachment',{attrs:{"data":_vm.rcbookphoto.back}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Insurance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Upload Insurance")]),_c('label',{staticStyle:{"font-size":"10px !important"}},[_vm._v("  (Only Pdf)")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('b-form-file',{attrs:{"placeholder":"Select File","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.handleFileChange($event, 'Insurance')}},model:{value:(_vm.insurance),callback:function ($$v) {_vm.insurance=$$v},expression:"insurance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('attachment',{attrs:{"data":_vm.insurance}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }