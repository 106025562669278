<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Vehicle Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Vehicle Name"
              >
                <b-form-input
                  v-model="vehiclename"
                  placeholder="Enter Vehicle Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Vehicle No.</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Vehical No"
              >
                <b-form-input
                  v-model="vehicleno"
                  maxlength="13"
                  placeholder="Vehical No."
                  @input="validateVehicalClick()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="vehicleValidate == false" class="text-danger"
                  >Enter Valid Vehical No</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Vehicle Colour</label>
              <b-form-input
                v-model="vehiclecolour"
                placeholder="Enter Vehicle Colour"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Fuel Type</label>
              <v-select
                v-model="fueltype"
                :options="['Petrol', 'Diesel', 'Gas', 'Electric', 'Hybrid']"
                placeholder="None"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Vehicle Type&nbsp;</label>
              <label style="font-size: 10px !important">
                (Suggestion : Fourwheeler , Twowheeler ...)</label
              >
              <b-form-input
                v-model="vehicletype"
                placeholder="Enter Vehicle Type"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Insurance Issue Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Insurance Issue Date"
              >
                <flat-pickr
                  v-model="insurancestartdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Insurance End Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Insurance End Date"
              >
                <flat-pickr
                  v-model="insuranceenddate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>PUC Issue Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PUC Issue Date"
              >
                <flat-pickr
                  v-model="pucstartdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>PUC End Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PUC End Date"
              >
                <flat-pickr
                  v-model="pucenddate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Photo"
              >
                <label>PUC Photo</label>
                <label style="color: red !important">*</label>
                <b-form-file
                  v-model="pucphoto"
                  placeholder="Select Photo"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @input="handleFileChange($event, 'pucphoto')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="pucphoto" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Photo"
              >
                <label>RC Book Front Side</label>
                <label style="color: red !important">*</label>
                <b-form-file
                  v-model="rcbookphoto.front"
                  placeholder="Select Photo"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @input="handleFileChange($event, 'rcbookphoto', 'front')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="rcbookphoto.front" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="RC Book Back Side"
              >
                <label>RC Book Back Side</label>
                <label style="color: red !important">*</label>
                <b-form-file
                  v-model="rcbookphoto.back"
                  placeholder="Select Photo"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @input="handleFileChange($event, 'rcbookphoto', 'back')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="rcbookphoto.back" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Insurance"
              >
                <label>Upload Insurance</label>
                <label style="font-size: 10px !important">
                  &nbsp;(Only Pdf)</label
                >
                <label style="color: red !important">*</label>
                <b-form-file
                  v-model="insurance"
                  placeholder="Select File"
                  drop-placeholder="Drop file here..."
                  @input="handleFileChange($event, 'Insurance')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="insurance" />
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../../components/Attechment.vue";

import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    Attachment,
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      vehiclename: "",
      vehicleno: "",
      vehiclecolour: "",
      fueltype: "",
      vehicletype: "",
      rcbookno: "",
      insurancestartdate: moment(new Date()).format("DD/MM/yyyy"),
      insuranceenddate: moment(new Date()).format("DD/MM/yyyy"),
      pucstartdate: moment(new Date()).format("DD/MM/yyyy"),
      pucenddate: moment(new Date()).format("DD/MM/yyyy"),
      rcbookphoto: {
        front: "",
        back: "",
      },
      vehicleValidate: true,
      testvehicle: "",
      testrc: "",
      insurance: "",
      flag: false,
      pucphoto: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Vehicle") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/vehicle");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/vehicle");
          }
        }
      });
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    validateVehicalClick() {
      const regex = new RegExp(
        /^[A-Z]{2}[\s]{1}[0-9]{2}[\s]{1}[A-Z]{1,2}[\s]{1}[0-9]{4}$/
      );
      this.vehicleno = this.vehicleno.toUpperCase();
      this.vehicleno.length == 2
        ? (this.vehicleno = this.vehicleno.concat(" "))
        : this.vehicleno;
      this.vehicleno.length == 5
        ? (this.vehicleno = this.vehicleno.concat(" "))
        : this.vehicleno;
      this.vehicleno.length == 8
        ? (this.vehicleno = this.vehicleno.concat(" "))
        : this.vehicleno;
      if (regex.test(this.vehicleno) == true) {
        this.vehicleValidate = true;
      } else {
        this.vehicleValidate = false;
      }
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getvehicleById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      this.vehiclename = data.vehiclename;
      this.vehicleno = data.vehicleno;
      this.testvehicle = data.vehicleno;
      this.vehiclecolour = data.vehiclecolour;
      this.fueltype = data.fueltype;
      this.vehicletype = data.vehicletype;
      this.rcbookno = data.rcbookno;
      this.testrc = data.rcbookno;
      this.insurancestartdate = data.insurancestartdate;
      this.insuranceenddate = data.insuranceenddate;
      this.pucstartdate = data.pucstartdate;
      this.pucenddate = data.pucenddate;
      this.rcbookphoto = data.rcbookphoto;
      this.insurance = data.insurance;
      this.pucphoto = data.pucphoto;
    },
    submitForm(e) {
      const data = {
        vehiclename: this.vehiclename,
        vehicleno: this.vehicleno,
        vehiclecolour: this.vehiclecolour,
        fueltype: this.fueltype,
        vehicletype: this.vehicletype,
        rcbookno: this.rcbookno,
        insurancestartdate: this.insurancestartdate,
        insuranceenddate: this.insuranceenddate,
        pucstartdate: this.pucstartdate,
        pucenddate: this.pucenddate,
        rcbookphoto: this.rcbookphoto,
        insurance: this.insurance,
        pucphoto: this.pucphoto,
      };
      if (this.testvehicle == this.vehicleno) {
        delete data.vehicleno;
      }
      if (this.testrc == this.rcbookno) {
        delete data.rcbookno;
      }
      // va: this =,this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false || this.vehicleValidate == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.vehicleValidate == true) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/vehicle/${this.$route.params.id}`
              : `${baseApi}/vehicle`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/master/crm/vehicle");
            })
            .catch((error) => {
              this.flag = false;

              let vehicalno, message;
              const code = error.toString().includes("409");
              if (code) {
                vehicalno = error.response.data.message.original.vehicalno
                  ? error.response.data.message.original.vehicalno[0]
                  : "";
                message = vehicalno;
              }
              this.$swal({
                title: "Error!",
                text: code ? message : `${error.response.data.message}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/master/crm/vehicle");
    },
    async handleFileChange(e, type, name) {
      if (e.type == "application/pdf" || type != "Insurance") {
        this.flag = true;
        const accessToken = localStorage.getItem("accessToken");
        const baseApi = process.env.VUE_APP_APIENDPOINT;
        const formData = new FormData();
        formData.append("image", e);
        formData.append("type", type);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
          data: formData,
          url: `${baseApi}/attachment`,
        };
        await axios(requestOptions)
          .then((response) => {
            if (type == "Insurance") {
              this.insurance =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
            } else if (type == "pucphoto") {
              this.pucphoto =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
            } else {
              this.rcbookphoto[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
            }
            this.flag = false;
          })
          .catch((error) => {
            this.flag = false;
            console.log(error, "error");
          });
      } else {
        this.insurance = "";
        this.$swal({
          title: "Error!",
          text: "Please Enter Valid File",
          icon: "error",
          timer: 5000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
